import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FooterWrapper, useStyles, Links, Link } from "./footer.style";

export default function Footer() {
  const classes = useStyles();
  return (
    <section id="contact">
      <FooterWrapper>
        <div className={classes.footerSection}>
          <div style={{ display: "grid" }}>
            <font className={classes.licenseText}>Contact & Social</font>
            <br />
            <font
              className={classes.licenseText}
              style={{ fontSize: "24px", fontWeight: 400 }}
            >
              Let’s connect and work together!
            </font>
          </div>
          <br />
          <br />
          <Links>
            <Link href="" target="_blank" onClick={()=>window.open("https://drive.google.com/file/d/1TAsG1GGcv9ny1YugdZVkOB3nxBe6VgZB/view", "_blank")}>
              Resume
            </Link>
            <Link href="mailto:jay_morales0@yahoo.com" target="_blank">
              Mail
            </Link>
            <Link
              href="https://www.linkedin.com/in/jay-morales"
              target="_blank"
            >
              Linkedin
            </Link>
            <Link
              href="https://www.instagram.com/jayceemoraless"
              target="_blank"
            >
              Instagram
            </Link>
          </Links>
        </div>
        <div
          style={{
            height: "93px",
            borderTop: "1px solid #696969",
            padding: "27px 96px",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
          }}
        >
          <font style={{ fontFamily: "Manrope", color: "#959595", alignSelf: "center" }}>
            Made by <font style={{ color: "white" }}>John Morales</font> —
            Copyright 2024
          </font>
          </div>
      </FooterWrapper>
    </section>
  );
}
